import { graphql, Link } from "gatsby";
import * as React from "react";

import Seo from "../components/seo";
import { ContentBox, ContentBg } from "../components/ui";

const Records = ({ data }) => {
  console.log(data);
  return (
    <ContentBg>
      <ContentBox>
        <Seo title="Records" />
        <h1>Records</h1>
        <p>
          If I do say so myself, I built a pretty cool app for my dad, over at{" "}
          <a href="https://dirkkater.nl" target="_blank">
            DirkKater.nl
          </a>
          . He owns his own jukebox and has 3,000+ vinyl "singles". Through his
          app (it's a PWA), he maintains which records are currently in his
          jukebox, which are for sale, etc. It even has a feature where{" "}
          <a href="https://dirkkater.nl/records/#in_jukebox">
            you can listen to the YouTube versions
          </a>{" "}
          of each the songs that are currently in his jukebox! :)
        </p>
        <p>
          I <em>don't</em> have a jukebox, nor 3,000+ records... but I do own a
          growing collection of vinyl records! I keep track of my records over
          on Discogs. But... I'm a frontend engineer by trade and I really
          dislike Discog's lack of UI. So, I built my own frontend here!
        </p>
        <p>Well, it'll show up here... soon! :)</p>
        {/* {data.allRecord.edges.map(edge => (
          <h2>
            {edge.node.artist.name} - {edge.node.title}
          </h2>
        ))} */}
      </ContentBox>
    </ContentBg>
  );
};

// export const query = graphql`
//   query {
//     allRecord {
//       edges {
//         node {
//           title
//           artist {
//             name
//           }
//         }
//       }
//     }
//   }
// `;

export default Records;
